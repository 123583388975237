import Image from 'next/future/image';

import type { TypeImageGraphQL } from '@/lib/graphql/types/basic';

interface TypeHeroSimple {
  desktopImage: TypeImageGraphQL;
  mobileImage: TypeImageGraphQL;
  slideIndex: number;
}

export const HeroSimple = ({
  desktopImage,
  mobileImage,
  slideIndex,
}: TypeHeroSimple) => (
  <picture>
    <source
      srcSet={desktopImage.sourceUrl}
      media="(min-width: 640px)"
      width={desktopImage.mediaDetails.width}
      height={desktopImage.mediaDetails.height}
      className="w-full object-contain"
      draggable={false}
    />
    <source
      srcSet={mobileImage.sourceUrl}
      width={mobileImage.mediaDetails.width}
      height={mobileImage.mediaDetails.height}
      className="w-full object-contain"
      draggable={false}
    />
    <Image
      src={mobileImage.sourceUrl}
      alt={mobileImage.altText}
      width={mobileImage.mediaDetails.width}
      height={mobileImage.mediaDetails.height}
      className="w-full object-contain"
      draggable={false}
      priority={slideIndex === 0}
    />
  </picture>
);
